import { Children, isValidElement, cloneElement } from "react";
import Link from "next/link";
import { cn } from "~/utils/cn";
import { Body, BodySize, BodyType } from "../typography";
import { TabsList, TabsTrigger, TabsRoot, TabsContent } from "./tabs-base";
import { TabProps, TabsProps } from "./tabs.types";

export const Tabs = ({
  tabs,
  variant = "primary",
  className,
  tabsListClassName,
  tabsContentClassName,
  orientation,
  children,
}: TabsProps) => {
  const defaultTabId = tabs.find(tab => tab.isActive)?.id ?? tabs[0].id;
  const isVertical = orientation === "vertical";

  const renderTriggerBody = ({ title, icon }: TabProps) => {
    return (
      <div className="text-center">
        {variant === "secondary" && icon && (
          <div className="mb-1 inline-flex h-10 w-10 items-center justify-center gap-2 rounded-xl p-2 group-data-[state=active]:bg-brand-primary-dark-00 group-data-[state=inactive]:bg-gray-200">
            <div className="flex h-6 w-6 items-center justify-center">
              <div className="w-4 text-center text-base font-normal text-white">
                <i
                  className={cn(
                    icon,
                    "group-data-[state=inactive]:text-neutral-disabled",
                  )}
                />
              </div>
            </div>
          </div>
        )}
        <Body
          size={BodySize.small}
          type={isVertical ? BodyType.basic : BodyType.emphasys}
          className={cn(
            "text-neutral-secondary group-data-[state=active]:text-neutral-primary",
            {
              "text-neutral-primary group-data-[state=active]:font-medium group-data-[state=active]:text-brand-primary-10":
                isVertical,
            },
            {
              "text-neutral-tertiary group-data-[state=active]:text-scalis-logo-color":
                variant === "secondary",
            },
          )}
        >
          {title}
        </Body>
      </div>
    );
  };

  return (
    <TabsRoot
      className={cn(
        "flex w-full gap-4",
        { "flex-col": !isVertical },
        className,
      )}
      defaultValue={defaultTabId}
      orientation={orientation}
    >
      <TabsList
        className={cn(
          "overflow-auto whitespace-nowrap",
          { "min-h-fit": !isVertical },
          { "flex flex-col border-b-0 border-r": isVertical },
          { "flex justify-evenly": variant === "secondary" },
          tabsListClassName,
        )}
      >
        {tabs.map(tab => (
          <TabsTrigger
            key={`tab-header-${tab.id}`}
            value={tab.id}
            asChild={tab.href != null}
            className={cn("group", {
              "justify-start data-[state=active]:border-b-0 data-[state=active]:bg-brand-primary-light-10":
                isVertical,
            })}
          >
            {tab.href ? (
              <Link href={tab.href}>{renderTriggerBody(tab)}</Link>
            ) : (
              renderTriggerBody(tab)
            )}
          </TabsTrigger>
        ))}
      </TabsList>

      {children ? (
        <>
          {Children.map(children, child => {
            if (
              isValidElement(child) &&
              "props" in child &&
              child.type === TabsContent &&
              tabs.some(tab => tab.id === (child.props as any).value)
            ) {
              return cloneElement(child);
            }
            return null;
          })}
        </>
      ) : (
        <>
          {tabs.every(tab => tab.href == null) && (
            <div className="flex flex-grow overflow-x-hidden">
              {tabs.map(tab => (
                <TabsContent
                  className="w-full"
                  key={`tab-content-${tab.id}`}
                  value={tab.id}
                >
                  {tab.content}
                </TabsContent>
              ))}
            </div>
          )}
        </>
      )}
    </TabsRoot>
  );
};
